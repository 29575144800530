import { omsAutoComplete } from './autocomplete';
import NProgress from 'nprogress';
import Validator from 'validator';

export function captchaLoad() {
  queryAll('.vf-captcha-button').forEach(button => {
    const callback_name = button.id.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }) + 'CaptchaSubmit';
    grecaptcha.render(button.id, {
        'sitekey': recaptchaSiteKey,
        'callback': callback_name
    });
  });
}
export function dropdown() {
  const hideDropdowns = () => queryAll('.dropdown').forEach(dropdown => dropdown.style.display = 'none');

  queryAll('.dropdown').forEach(dropdown => {
    query(`[data-dropdown="${dropdown.id}"]`)?.addEventListener('click', () => {
      event.preventDefault();
      event.stopPropagation();
      hideDropdowns();
      const parentRect = event.currentTarget.getBoundingClientRect();
      dropdown.style.display = 'inline-block';
      dropdown.style.top = parentRect.bottom;
      dropdown.style.left = `${parentRect.left}px`;
    });
  });
  // hide the dropdown if clicked anywhere else
  // because the click handler on the dropdown stops event propagation, this will not run if clicked on the dropdown
  document.addEventListener('click', hideDropdowns);
}

// NPROGRESS
export function progress() {

  globalListener('turbolinks:request-start',   function() { NProgress.start(); });
  globalListener('turbolinks:render',  function() { NProgress.done(); });
  globalListener('turbolinks:load', function() { NProgress.remove(); });
}

// HEADER
export function applicationHeader() {

  ['DOMContentLoaded', 'turbolinks:load'].forEach(e => {
    globalListener(e, () => {
      omsAutoComplete({
        selector: '#vf-search',
        serviceUrl: '/search',
        waitForEnter: true,
        onSelect: suggestion => {
          if (suggestion.data) {
            Turbolinks.visit(suggestion.data);
          }
          else {
            query('#vf-instant-dropdown a').click();
          }
        }
      })
    });
  });
}

// INSTANT
export function instant({ isMobile }) {
  const formId = isMobile ? 'vf-mobile-instant-form' : 'vf-instant-form';
  const validator = new Validator();
  validator.submitWithRemoteValidation(formId);

  return function() {
    query(`#${formId}`).submit();
  }
}

// MOBILE FLOAT IN
export function mobileFloatIn(selector, direction, extraCSS, hideOthers) {
  hideOthers = hideOthers || '.vf-floatin';
  queryAll(selector).forEach(item => item.addEventListener('click', event => {
    event.preventDefault();

    const submenu = query(`#${event.currentTarget.dataset.submenu}`);
    const show = !submenu.style.length;

    if (hideOthers !== false) {
      queryAll(hideOthers).forEach(toHide => toHide.removeAttribute('style'));
    }

    if (show) {
      submenu.style.visibility = 'visible';
      if (extraCSS != null) {
        extraCSS.forEach(item => submenu.style[item.key] = item.value);
      }
      submenu.style[direction] = '0';
    }
    else {
      submenu.removeAttribute('style');
    }
  }));
}

// MOBILE NAVIGATION

export function mobileNav() {
  mobileFloatIn('#vf-mobile-a-panel', 'left', [{ key: 'box-shadow', value: '5px 5px 5px rgba(0,0,0,0.15)' }]);
}

export function mobileFooterNav() {
  mobileFloatIn('.vf-mobile-footer-menu', 'left', [{ key: 'box-shadow', value: '5px -5px 5px rgba(0,0,0,0.15)' }]);
}